import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import * as styles from "../assets/styles/ui-ux.module.scss"
import "../assets/styles/commons/lists/_common__numeric-columns__block.scss"
import "../assets/styles/commons/_lists.scss"
import "../assets/styles/commons/common__animated-section.scss"
import AnimatedLayer from "../components/animated-layer"
import TestimonialsSlider from "../components/testimonials-slider"
import ContactForm from "../components/contact-form"
import { IMG } from "../const"

const STEPS = [
  {
    id: 1,
    title: "You tell us your story",
    text:
      "We would love to hear your story and learn your vision so that to understand needs and challenges. It is essential for us to understand your problem and prepare a proper solution.",
    image: "ui-ux1",
    imageTitle: "Talking about blitz designs",
  },
  {
    id: 2,
    title: "We create a sample page",
    text:
      "You will have a chance to see a sample page to both of us make sure that we are moving in the right way. After the sample page approving, we will be able to deliver a more insightful version.",
    image: "ui-ux2",
    imageTitle: "Blitz product estimation",
  },
  {
    id: 3,
    title: "We modify the page according to suggestions",
    text:
      "As soon as we agree on the best version of the page, we will modify and improve it based on your preferences.",
    image: "ui-ux3",
    imageTitle: "Blitz design modification",
  },
  {
    id: 4,
    title: "We create all other pages",
    text:
      "We use the best in class tools for communication and collaboration, where you can easily interact with a designer, receive notifications as soon as a new design is ready, comment and share results with your team and potential clients.",
    image: "ui-ux4",
    imageTitle: "Design blitz web-site",
  },
]

const UiUx = () => {
  return (
    <Layout
      pageTitle="UI/UX Blitz — The easiest way to design a startup"
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-blitz-product-design.jpg`}
    >
      {({
        handleOpen,
        isOpenContactModal,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={styles.uiBlock}>
            <article>
              <section className="common__animated-section">
                <AnimatedLayer>
                  <div className="common__animated-section__heading">
                    <div className="common__animated-section__row common__article__section">
                      <div className="common__animated-section__col">
                        <h1>UI/UX Blitz design</h1>
                        <h2>Our offer design for startups</h2>
                        <h3>
                          Need a design that looks impressive in just 100 hours?
                          We prepared an incredible offer for you!
                        </h3>
                        <button
                          className="goldButton"
                          onClick={handleOpen}
                          title="Contact us"
                          aria-expanded={isOpenContactModal ? "true" : "false"}
                          aria-label={
                            isOpenContactModal
                              ? "Hide contact form"
                              : "Show contact form"
                          }
                        >
                          Contact&nbsp;us
                        </button>
                      </div>
                      <div className="common__animated-section__static-img">
                        <div className="common__animated-section__img-wrapper">
                          <img
                            src={`${IMG.UI}/rocket.svg`}
                            width="84"
                            height="182"
                            alt="Blitz design agency"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimatedLayer>
              </section>
              <section className="common__article__section">
                <h2>First impression</h2>
                <p>
                  Initially a lot of startups have a very limited funds to
                  visualize their idea. However, a first impression is very
                  important and has a long lasting effect. If you catch
                  investors or potential users with a design that attracts, they
                  are more likely to overlook and forgive you other minor
                  issues.
                </p>
                <p>
                  Additionally, after positive first impression people are more
                  likely to give you better rates, they trust the service more
                  and more likely to return again and again.
                </p>
              </section>
              <section className="common__article__section__doubleIndentBottom common__numeric-columns__block">
                <h2>How it works?</h2>
                <ol className="common__numeric-columns__block__content">
                  {STEPS.map(item => (
                    <li
                      className="common__numeric-columns__block__item"
                      key={item.id}
                    >
                      <picture className="common__numeric-columns__block__picture">
                        <source
                          srcSet={`${IMG.UI_UX}/${item.image}.avif,	${IMG.UI_UX}/${item.image}@2x.avif 2x,	${IMG.UI_UX}/${item.image}@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.UI_UX}/${item.image}.webp,	${IMG.UI_UX}/${item.image}@2x.webp 2x,	${IMG.UI_UX}/${item.image}@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.UI_UX}/${item.image}.jpg,		${IMG.UI_UX}/${item.image}@2x.jpg 2x,	${IMG.UI_UX}/${item.image}@3x.jpg 3x`}
                        />
                        <img
                          src={`${IMG.UI_UX}/${item.image}@3x.jpg`}
                          alt={item.imageTitle}
                          itemProp="contentUrl url"
                          width="664"
                          height="160"
                          loading="lazy"
                        />
                        <meta itemProp="width" content="1992" />
                        <meta itemProp="height" content="480" />
                      </picture>
                      <div className="common__numeric-columns__block__text">
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </section>
              <section className="common__article__section upTo2cols">
                <section>
                  <h2 className="section-title">Impact</h2>
                  <ul className="list__checkmarked">
                    <li>Savings during a development stage</li>
                    <li>Better look and feel of your product</li>
                    <li>Improved credibility</li>
                    <li>More happy users</li>
                    <li>More impressed investors</li>
                  </ul>
                </section>
                <section>
                  <h2 className="section-title">Deliverables</h2>
                  <ul className="list__checkmarked">
                    <li>
                      A better understanding of the product and its structure
                    </li>
                    <li>5-7 pages of your product</li>
                    <li>Professional user interface</li>
                    <li>Clickable prototype without any coding</li>
                    <li>More productive and efficient development team</li>
                  </ul>
                </section>
              </section>
              <section className="bg__nobleGray">
                <div className="common__article__section common__projects-teaser">
                  <h2>Our Successful Projects</h2>
                  <p>
                    Here, you can check out our successful projects! We have
                    described the most compelling solutions and approaches to
                    meet challenges and advantages.
                  </p>
                  <Link to="/projects/" className="seeMore">
                    <figure>
                      <picture className="portfolioChapter__singleProjectSummary__screenshotPlaceholder">
                        <source
                          srcSet={`${IMG.PORTFOLIO}/ageline/382.avif,	${IMG.PORTFOLIO}/ageline/764.avif 2x,	${IMG.PORTFOLIO}/ageline/984.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.PORTFOLIO}/ageline/382.webp,	${IMG.PORTFOLIO}/ageline/764.webp 2x,	${IMG.PORTFOLIO}/ageline/984.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.PORTFOLIO}/ageline/382.jpg,	${IMG.PORTFOLIO}/ageline/764.jpg 2x,	${IMG.PORTFOLIO}/ageline/984.jpg 3x`}
                        />
                        <img
                          src={`${IMG.PORTFOLIO}/ageline/984.jpg`}
                          alt="Discover blitz it works"
                          width="288"
                          height="164"
                          loading="lazy"
                        />
                      </picture>
                      <picture className="portfolioChapter__singleProjectSummary__screenshotPlaceholder">
                        <source
                          srcSet={`${IMG.PORTFOLIO}/thespoke/382.avif,	${IMG.PORTFOLIO}/thespoke/764.avif 2x,	${IMG.PORTFOLIO}/thespoke/984.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.PORTFOLIO}/thespoke/382.webp,	${IMG.PORTFOLIO}/thespoke/764.webp 2x,	${IMG.PORTFOLIO}/thespoke/984.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.PORTFOLIO}/thespoke/382.jpg,		${IMG.PORTFOLIO}/thespoke/764.jpg 2x,		${IMG.PORTFOLIO}/thespoke/984.jpg 3x`}
                        />
                        <img
                          src={`${IMG.PORTFOLIO}/thespoke/984.jpg`}
                          alt="Discover blitz design projects"
                          width="288"
                          height="164"
                          loading="lazy"
                        />
                      </picture>
                    </figure>
                    <span>Discover projects</span>
                  </Link>
                </div>
              </section>
              <section className="common__article__section">
                <h2 className="section-title">Clients’ testimonials</h2>
                <TestimonialsSlider />
              </section>
            </article>
            <section className={styles.contactForm}>
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default UiUx
